class Sections
{
  constructor()
  {
    this.sections = {
      LOADER: '/loader',
      HOME: '/',
      SUPPORT: '/support',
      PRIVACY_POLICY: '/privacy-policy',
      TERMS_OF_SERVICE: '/terms-of-service',
      THANK_YOU: '/thank-you'
    };

    this.scrollable_sections = [
    ];
  }

  start()
  {
    this.sections_array = Object.values(this.sections);
  }

  includes(section)
  {
    return this.sections_array.includes(section);
  }

  __create_sections_from_data(objs, parent, is_scrollable)
  {
    for (let i = 0; i < objs.length; i++)
    {
      this.sections[`${parent}_${objs[i].id.toUpperCase()}`] = objs[i].url;

      if (is_scrollable)
      {
        this.scrollable_sections.push(objs[i].url);
      }
    }
  }
}

export default new Sections();
