import ApplicationView from './common/ApplicationView';
import Sections from '../components/Sections';

export default class SupportView extends ApplicationView
{
  start()
  {
    super.start(Sections.sections.SUPPORT, document.querySelector('.support'));
  }
}
