import Idle from './Idle';

export default class Hiding
{
  constructor()
  {
    this.t = 0;
    this.initial_opacity = 0;
    this.target_opacity = 0;
    this.velocity = 0.1;
  }

  on_enter(view)
  {
    this.initial_opacity = view.container.style.opacity;
  }

  update(view)
  {
    this.t += 0.016 / this.velocity;
    this.easing_function_t = this.ease_in_out_cubic(this.t);
    this.easing_function_t = this.clamp(this.easing_function_t, 0, 1);

    view.container.style.opacity = this.lerp(this.initial_opacity, this.target_opacity, this.easing_function_t);

    if (this.easing_function_t >= 0.9999)
    {
      view.set_displaying_state(new Idle());
      view.container.classList.add('hidden');
    }
  }

  update_t(view, t)
  {
    view.container.style.opacity = 1 - t;

    if (t >= 0.9999)
    {
      view.set_displaying_state(new Idle());
      view.container.classList.add('hidden');
    }
  }

  on_exit(view)
  {

  }

  ease_in_out_cubic(x)
  {
    return x < 0.5
      ? 4 * x * x * x
      : 1 - Math.pow(-2 * x + 2, 3) / 2;
  }

  clamp(value, min, max)
  {
    return Math.max(min, Math.min(max, value));
  }
}
