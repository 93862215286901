
export default class RenderLoop
{
  constructor(target_application)
  {
    this._frame_id = -1;

    this.target_application = target_application;

    this.is_running = false;
    this.frames_passed = 0;
  }

  update()
  {
    if (!this.is_running)
    {
      return;
    }

    // ###### START CYCLE ######
    if (this.frames_passed === 1)
    {
      this.target_application.on_post_start();
    }

    this.target_application.update();

    this._frame_id = requestAnimationFrame(this.update.bind(this));
    this.frames_passed++;
  }

  start()
  {
    if (this.is_running) return; // sanity check

    if (this.frames_passed === 0)
    {
      this.target_application.start();
    }

    this.is_running = true;
    this.update();
  }

  stop()
  {
    if (this.is_running === false) return; // sanity check

    this.is_running = false;
    this.target_application.on_exit();

    cancelAnimationFrame(this._frame_id);
  }

  set_state(new_state)
  {
    this.target_application.on_exit(this);
    this.target_application = new_state;
    this.target_application.on_enter(this);
  }

  dispose()
  {
    this.stop();
    this.frames_passed = 0;
  }
}
