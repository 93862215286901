import Sections from '../components/Sections';
import ApplicationView from './common/ApplicationView';

export default class TermsOfServiceView extends ApplicationView
{
  start()
  {
    super.start(Sections.sections.TERMS_OF_SERVICE, document.querySelector('.terms-of-service'));
  }
}
