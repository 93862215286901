
// APP
import RenderLoop from './js/components/RenderLoop';
import MainApplication from './MainApplication';

const application = new MainApplication();
const render_loop = new RenderLoop(application);

window.ViewApi = {
  init: (parameters) =>
  {
    // let body = document.querySelector('body');
    // let canvas = document.getElementById('main-canvas');
    window.app = application;
  },

  dispose: () =>
  {
    render_loop.stop();
  },

  draw_debug_axis: () =>
  {
  },

  register_event: (name, callback) =>
  {
  },

  resize_canvas: () =>
  {
    application.on_resize();
  },

  // resource_loading_completed: () =>
  // {
  //   application.resources_fully_loaded();
  // },

  set_resource: (name, resource) =>
  {
  },

  start: () =>
  {
    render_loop.start();
  },

  stop: () =>
  {
    render_loop.stop();
  },

  application: application
};
