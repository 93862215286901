
import BaseApplication from './js/components/BaseApplication';
import Sections from './js/components/Sections';
import HomeView from './js/html_components/HomeView';
import Modal from './js/html_components/Modal';
import PrivacyPolicyView from './js/html_components/PrivacyPolicyView';
import SupportView from './js/html_components/SupportView';
import TermsOfServiceView from './js/html_components/TermsOfServiceView';
import ThankYouView from './js/html_components/ThankYouView';
import HTMLManager from './js/html_components/common/HTMLManager';

export default class MainApplication extends BaseApplication
{
  constructor()
  {
    super();
  }

  start()
  {
    this.modal = Modal;

    this.home_view = new HomeView();
    this.support_view = new SupportView();
    this.privacy_policy_view = new PrivacyPolicyView();
    this.terms_of_service_view = new TermsOfServiceView();
    this.thank_you_view = new ThankYouView();

    this.home_view.start();
    this.support_view.start();
    this.privacy_policy_view.start();
    this.terms_of_service_view.start();
    this.thank_you_view.start();

    Sections.start();
    HTMLManager.start();
  }

  update()
  {
    HTMLManager.update();
  }

  on_resize()
  {
    HTMLManager.on_resize();
  }

  post_start()
  {
    this.on_resize();
  }
}
