import ApplicationView from './common/ApplicationView';
import Sections from '../components/Sections';

/* global fbq gtag */
export default class ThankYouView extends ApplicationView
{
  start()
  {
    super.start(Sections.sections.THANK_YOU, document.querySelector('.thank-you'));
  }

  on_enter()
  {
    fbq('track', 'CompleteRegistration');
    gtag('event', 'conversion', { send_to: 'AW-330743563/MsdnCNWDg-ECEIv-2p0B' });
  }
}
