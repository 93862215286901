import ApplicationView from './common/ApplicationView';
import Sections from '../components/Sections';

export default class PrivacyPolicyView extends ApplicationView
{
  start()
  {
    super.start(Sections.sections.PRIVACY_POLICY, document.querySelector('.privacy-policy'));
  }
}
