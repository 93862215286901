import HTMLManager from './HTMLManager';
import State from './State';
import Idle from './visibility_states/Idle';
import Showing from './visibility_states/Showing';
import Hiding from './visibility_states/Hiding';

export default class ApplicationView extends State
{
  constructor()
  {
    super();

    this.name = undefined;
    this.container = undefined;
    this.current_displaying_state = new Idle();

    this.show_velocity = 0.1;
    this.hide_velocity = 0.05;
  }

  start(name, container)
  {
    this.name = name;
    this.container = container;

    HTMLManager.listen_to_section_changes(this);
  }

  show()
  {
    this.set_displaying_state(new Showing(1));
  }

  hide()
  {
    this.set_displaying_state(new Hiding());
  }

  on_resize()
  {
  }

  update_display(t)
  {
    this.current_displaying_state.update_t(this, t);
  }

  set_displaying_state(state)
  {
    // console.log(state.constructor.name);
    this.current_displaying_state.on_exit(this);
    this.current_displaying_state = state;
    this.current_displaying_state.on_enter(this);
  }
}
