import ApplicationView from './common/ApplicationView';
import Sections from '../components/Sections';
import Modal from './Modal';

/* global fbq */
export default class HomeView extends ApplicationView
{
  start()
  {
    super.start(Sections.sections.HOME, document.querySelector('.home'));

    // this.download_button = document.querySelector('.home__button');
    // this.download_button_text = 'Download ';

    this.mac_container = document.querySelector('.home__mac');
    this.windows_container = document.querySelector('.home__windows');
    this.linux_container = document.querySelector('.home__linux');

    switch (this.get_os())
    {
    case 'mac' :
      this.mac_container.classList.remove('hidden');
      break;
    case 'windows':
      this.windows_container.classList.remove('hidden');
      break;
    case 'linux':
      this.linux_container.classList.remove('hidden');
      break;
    default:
      this.mac_container.classList.remove('hidden');
    }

    this.video_container = document.querySelector('.home__video');
    this.video = document.querySelector('.home__video-video');
    this.video_volume_icon = document.querySelector('.home__video-volume');

    // this.download_button.textContent = this.download_button_text;
  }

  on_video_ended()
  {
    this.video.setAttribute('muted', 'true');
    this.video.muted = true;

    this.video_container.classList.add('cursor-pointer');
    this.video_volume_icon.classList.remove('hidden');

    this.video.play();
  }

  on_video_click()
  {
    this.video.removeAttribute('muted');
    this.video.muted = false;

    this.video_container.classList.remove('cursor-pointer');
    this.video_volume_icon.classList.add('hidden');
  }

  on_container_click()
  {
    fbq('track', 'ViewContent');

    setTimeout(() =>
    {
      window.open('https://compress.ohzi.io/thank-you', '_self');
    }, 500);
  }

  show_differences(os)
  {
    const store = os === 'mac' ? 'Mac App Store' : 'Microsoft Store';
    let title = 'What\'s the difference?';
    // let path = `images/casestudies/${Configuration.is_mobile ? 'mobile' : 'originals'}/${case_image}`;
    let body = `<div class="home__differences">
                  Compress is completely free. The ${store} version is identical to the free version and there are no features left out of the free version. Purchasing the store version directly funds the development of Compress and shows your support
                  <span><svg class="home__heart-icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-heart fa-w-16 fa-2x"><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" class=""></path></svg></span>
                  .

                </div>
                `;

    Modal.show({ title, body });
  }

  show_mac_options()
  {
    let title = 'Select your version';
    // let path = `images/casestudies/${Configuration.is_mobile ? 'mobile' : 'originals'}/${case_image}`;
    let body = `<div class="home__mac-options">
                  Select the version of Compress that’s right for your Mac
                  <div class="home__mac-options-buttons">
                    <div class="home__mac-options-buttons-intel">
                      <a class="button home__button" href="https://s3.sa-east-1.amazonaws.com/cloudtag.io/releases-compress/Compress_x64.dmg">Mac with Intel chip</a>
                      MOST COMMON
                    </div>
                    <a class="button home__button home__button--secondary" href="https://s3.sa-east-1.amazonaws.com/cloudtag.io/releases-compress/Compress_arm64.dmg">Mac with Apple chip</a>
                  </div>
                </div>
                `;

    Modal.show({ title, body });
  }

  get_os()
  {
    let userAgent = window.navigator.userAgent;
    let platform = window.navigator.platform;
    let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    let iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1)
    {
      os = 'mac';
    }
    else if (iosPlatforms.indexOf(platform) !== -1)
    {
      os = 'ios';
    }
    else if (windowsPlatforms.indexOf(platform) !== -1)
    {
      os = 'windows';
    }
    else if (/Android/.test(userAgent))
    {
      os = 'android';
    }
    else if (!os && /Linux/.test(platform))
    {
      os = 'linux';
    }

    return os;
  }
}
